@charset "utf-8";

@import "../node_modules/bulma/sass/utilities/_all.sass";
@import "../node_modules/bulma/sass/elements/button.sass";
@import "../node_modules/bulma/sass/elements/container.sass";
@import "../node_modules/bulma/sass/layout/hero.sass";

.app { position: relative; min-height: 100vh; }
.App { position: relative; min-height: 100vh;
  //background-color: darkgreen;
}
img { width: 100%; }
.nomad-container { width: 60%; margin: 0 auto; }
#shop-now { font-weight: 600;
  background-color: darkgreen; color: white;
  &:hover { background-color: #606060; }
}

// Featured products section
.featured-collection { margin-top: 2rem; width: 100vw; overflow: hidden;
  position: relative; height: 79vh; min-height: 30vw; //40vh //80vh
  .featured-section-title { font-size: 2rem; margin-left: 13vw; }
  .featured-image { cursor: pointer; }
  //.products { } // display: block;
  @media(min-width: 1024px) { margin-top: 6rem;
    .featured-section-title { margin-left: 1vw; } //113vw, 113/5=22.5, 22.5*6=135vw
    .products { display: flex; width: 135vw; position: absolute; left: 0; //-23vw;
      justify-content: space-between; flex-wrap: nowrap; padding: 5px; //wrap
      div { flex: 0 0 250px; } //300px
      .featured-product { margin: 10px; }
    }
    .products.animate { left: -23vw; transition: left 0.5s ease-in-out; } //all
    .products.animate:hover { left: 0; transition: none }
} }

// single product page
.name-price {
  p { font-size: 1.2rem; font-weight: bold; }
  h3 { font-size: 1rem; }
}

//black button styles
.nomad-btn { display: block;
  width: 100%; line-height: 1.4; padding-left: 5px; padding-right: 5px;
  white-space: normal; margin-top: 0; margin-bottom: 10px; min-height: 44px;
}

.org-btn { display: block;
  width: 100%; line-height: 1.4; padding-left: 0; padding-right: 0;
  white-space: normal; margin-top: 0; margin-bottom: 10px; min-height: 44px;
}

//input styles
.nomad-input { width: 100%; padding: 10px; margin-bottom: 1rem; font-family: Arial, Helvetica, sans-serif; }
.data-form { vertical-align: middle; div { vertical-align: middle; }
  label { width: 30vw; display: inline-block; vertical-align: middle; }
  label.location { padding-bottom: 7px; }
  //label.phone { margin-bottom: 0.4rem; }
  input[type=text],input[type=date],
  input[type=number],input[type=email],input[type=password] { width: 60%; }
  label.name { display: none } div.name { margin-bottom: 1rem; }
  label.phone, label.password, label.otp { margin-bottom: 0.4rem; }
  input[type=text].otp, label.otp, input[type=password].newpass, label.newpass { width: 88%; }
  select.nomad-input { width: 100%; }
  div.nomad-input { width: 35%; display: inline-block; border: 1px solid #666;
    label { width: 18vw; }
  }
}
form { padding: 1rem 0.5rem; text-align: center; input[type=text],input[type=date],
  input[type=number],input[type=email],input[type=password] { width:60%; }
  select.nomad-input { width: 67%; }
  .submit-btn { display: flex; justify-content: center; }
  .submit { width: 200px; }
}
.form-error { border: 1px solid red; }
::placeholder { color: #606060; font-size: 16px; }
#btn-white-outline { border: 1px solid grey; margin-bottom: 1rem; }
#buy-it-now-btn { &:hover {background-color: #606060;} }

// Proceed to Checkout
.proceed { width: 48vw; margin: 0 auto; }
.hide-in-mobile,.hide-mobi-block { display: none; } .hide-in-desktop { display: block; }
.inblock-in-mobile { display: inline-block; }
.flex,.flex-in-mobile { display: flex; }

@media(min-width: 1024px) {
  .proceed { width: 15vw; }
  .hide-in-desktop { display: none; }
  .flex-in-mobile,.hide-mobi-block { display: block; }
  .hide-in-mobile, .inblock-in-mobile { display: flex; }
  .line { display: flex; align-items: center; justify-content: center; }
  .wide-line { display: flex; align-items: center; justify-content: space-between; }
  .data-form { select.nomad-input { width: 37.5%; }
    label.location { text-align: left; margin-left: -45px; }
    //label.phone { margin-bottom: 1.4rem; flex: 1; }
    label { width:30vw; } input[type=text], input[type=date],
    input[type=number], input[type=email], input[type=password] { width:35%; }
    label.name { display: block; flex: 1; }
    div.name { margin-top: -1rem; margin-bottom: 0.8rem; text-align: left; flex: 1; }
    label.phone, label.password, label.otp, label.newpass { margin-bottom: 1.4rem; flex: 1; }
    div.nomad-input { width: 35%; border: 1px solid #666; //display: inline-block;
      label { width: 18vw; }
    }
  }
  form { width: 60%; padding: 2rem; margin: 0 auto; input[type=text], input[type=date],
    input[type=number],input[type=email],input[type=password] { width: 65%; }
    select.nomad-input { width: 70%; }
  }
}

.error { border: 1px solid red; }
.error-message { color: red; font-size: 1.5rem; }
