.header {
  background-color: darkgreen; color: white; margin: 0 !important;
  max-width: 100vw !important; //padding: 1rem 2.65vw !important; //3vw
  padding: 0 2vw 0.02rem 0.8vw !important; //0.2rem //1.2vw //2.65vw //3vw //2vw
  //a { color: white; }
}
.nav-menu { display: flex; padding: 1rem; min-height: 72px; height: 12vh; //15vh //80px
  a { text-decoration: none; color: white; } a:hover { color: yellow; }
  .sign-out { &:hover { color: yellow; } }
  //&:focus,&:hover,&:visited { color: black; }
  .logo { font-size: 1.5rem; position: absolute; top: 0.76vh; //1.5vh
    img { width: 18vw; min-width: 50px; } //13vw //17vw
    a .text { display: none; }
  }
  ul { list-style-type: none; margin: 0 1vw 0 23vw; //margin: 0; margin-left: 16vw
    padding: 0; padding-top: 10px; padding-right: 4vw;
    li { display: inline-block; margin: 0 1rem; cursor: pointer; }
    li.sign-up { display: none; }
  }
}
.locatn { padding-left: 1vw; width: 48vw; display: inline-block; }
.user { text-align: right; width: 49vw; display: inline-block;
  /* padding-right: 1rem; span { text-align: right; background-color: blue; } */
}

@media(min-width: 1024px) {
  .header {
    padding: 1rem 2vw 0 0.25vw !important; //2.65vw //3vw //1.2vw
  }
  .nav-menu { height: 18vh;
    .logo { top: 0.8vh; //2.5vh
      img { width: 7vw; } //6vw
      a .text { display: inline-block; width: 30vw; // vertical-align: middle;
        position: absolute; top: 3.5vh; left: 38vw; //11vh //10vh 40vw
        font-size: 3rem;
      }
    }
    ul { margin-left: 66vw; //auto;
      li.sign-up { display: inline-block; }
    }
  }
}
