.cart-container { margin-left: auto; cursor: pointer; width: 82px; //65px //55px //14vw;
  padding-top: 10px;
  img { width: 50px; //30px //display: inline-block; margin-left: 5vw;
  }
  .cart-count { //padding: 8px;
    margin-left: -4px; //-10px //14.5px //8px //13px //0px;
    //background-color: black; color: white;
    background-color: orange; color: black;
    padding-right: 4px; border-radius: 10px; //margin-top: -250px; display: inline-block;
    //line-height: 1px;
  }
  @media(min-width: 642px) { width: auto;
    .cart-count { align-items: end; text-align: right;
      //margin-left: -10px; //padding-top: 50px; //margin-top: 50px;
      //margin-top: 0;
    }
  }
}
