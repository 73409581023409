.cust-order { margin-left: 2vw;
  .items-container { margin-right: 0;
    .order-item { width: 96vw; margin-right: 0; margin-bottom: 3vh;
      .price,.delivery { margin: 0;} .title { flex: 2; }
      .quantity,.price,.line-total { flex: 1; }
    }
  }
  .btns-container { display: flex; width: 8vw; text-align: center; margin: 0;
    .btn-increase { color: #fff; background-color: #1a1a1a; flex: 1; }
    .btn-decrease, .btn-trash { color: #fff; background-color: #d9534f; flex: 1; }
    .btn-cancel { color: #fff; background-color: #1a1a1a; flex: 1; margin-left: 5px; }
    .btn-revive { color: #00f; font-weight: bold; flex: 1; }
    .btn-change { color: #555; background-color: #ecc; font-weight: bold; flex: 1;
      margin-bottom: 5px; margin-left: 5px; margin-right: 5px; }
    .btn-prepare { color: #fff; background-color: #00f; margin-bottom: 1vh; flex: 1; }
    .btn-assign { color: #fff; background-color: #174; margin-bottom: 1vh; flex: 1; }
    .btn-deliver { color: #fff; background-color: #570; margin-bottom: 1vh; flex: 1; }
    .btn-reorder { color: #fff; background-color: #14c; margin-bottom: 1vh; flex: 1; }
  }
  .pmt-btns { margin-left: 0; //6vw;
    .btn-refund { color: #fff; background-color: #d9534f; }
    .btn-invoice,.btn-sms { color: #555; background-color: #c9c9fe; font-weight: bold; }
  }
  .name, .title { font-weight: bold; }
  .cancelled { font-weight: bold; color: red; }
  .preparing { font-weight: bold; color: #73d; }
  .assigned { font-weight: bold; color: #3b6; }
  .delivered { font-weight: bold; color: #780; }
  .re-ordered { font-weight: bold; color: #35f; }
}
@media(min-width: 1024px) {
    .cust-order { margin: 0.5vh 0.2vw 4.5vh;
        div { margin: 0 2vw; }
        .pmt-method { .pmt-status { margin: 0; position: relative;
            .pmt-btns { position: absolute; top: 1vh; margin-left: 6vw; //width: 25vw;
              button { margin-top: 1vh; /*display: inline-block;*/ }
              // .btn-refund { color: #fff; background-color: #d9534f; }
              // .btn-invoice { color: #000; background-color: #c9c9fe; } //#e9e9e9
            }
        } }
        .items-container { margin-right: 0;
          .order-item { width: 32vw; /* margin-right: 0; margin-bottom: 3vh;
            .price,.delivery { margin: 0;} */
          }
        }
        .shipping { padding-left: 3vw; width: 35vw; margin-left: auto; }
        .btns-container { display: block; /* width: 8vw; text-align: center; margin: 0;
            .btn-increase { color: #fff; background-color: #1a1a1a; }
            .btn-decrease, .btn-trash { color: #fff; background-color: #d9534f; }
            .btn-cancel { color: #fff; background-color: #1a1a1a; }
            .btn-revive { color: #00f; font-weight: bold; }
            .btn-prepare { color: #fff; background-color: #00f; margin-bottom: 1vh; }
            .btn-assign { color: #fff; background-color: #174; margin-bottom: 1vh; }
            .btn-deliver { color: #fff; background-color: #570; margin-bottom: 1vh; }
            .btn-reorder { color: #fff; background-color: #14c; margin-bottom: 1vh; } */
        }
        // .name, .title { font-weight: bold; }
        // .cancelled { font-weight: bold; color: red; }
        // .preparing { font-weight: bold; color: #73d; }
        // .assigned { font-weight: bold; color: #3b6; }
        // .delivered { font-weight: bold; color: #780; }
        // .re-ordered { font-weight: bold; color: #35f; }
    }
}
