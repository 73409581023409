.single-product-container {
  margin: 2rem 0; margin-bottom: 3rem; padding: 2rem;
  @media(min-width: 1024px) {
    display: flex; justify-content: center; align-items: center;
    .product-image, .product-details { flex: 0 0 400px; }
  }
  .product-image { margin: 2rem; }
  .product-details {
    .name-price { margin: 1rem 0; }
  }
  .add-to-cart-btns { margin: 2rem 0; }
  .product-description {
    p { line-height: 1.5; }
  }
}
