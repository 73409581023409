h1, .empty-cart { text-align: center; }
.cart-page {
  .total-container {
    text-align: center; //margin: 2rem;
  }
}
.cart-item {
  text-align: center; border: 1px solid #ccc; margin: 2rem; padding: 1rem;
  .item-image { img { width: 50px; } }
  .name-price {
    h4 { margin: 0; }
    p { font-size: 1rem; font-weight: normal; }
  }
  .btns-container {
    button { border: none; padding: 0.5rem 1rem;
      cursor: pointer; font-size: 10px; margin-right: 5px;
    }
    .btn-increase { color: #fff; background-color: #1a1a1a; }
    .btn-decrease, .btn-trash { color: #fff; background-color: #d9534f; }
  }
  .quantity {
    p br { display: none; }
  }
  .cart-form { width: 90%; padding: 1rem 0.5rem;
    select { padding: 10px; min-width: 15vw; margin-left: 2vw; }
    input { padding: 9px; min-width: 13.5vw; }
    input[type=date] { width: 23.5vw; margin-left: 2vw; }
    div { margin-left: 0; }
  }
}
@media(min-width: 1024px) {
  .cart-page {
    display: flex; justify-content: center;
    //.total-container { text-align: center; }
  }
  .cart-item {
    // display: flex; justify-content: space-between;
    div { margin: 0 1rem;
      p { margin: 0.5rem; }
    }
    // name-width: { width: 200px; }
    .subscription { margin: 1rem; }
    .quantity {
      p br { display: inline; }
    }
    .cart-form { padding: 1rem 2rem;
      select, input[type=date] { margin: 0; }
      input[type=date] { width: 13.5vw; }
    }
  }
}
