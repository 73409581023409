.checkout {
  width: 98%; margin: 0 auto; text-align: center;
  h4 { margin: 0; margin-top: 1rem; }
  button { width: 200px; margin: 0 auto; }
  button.is-grey { background-color: #e9e9e9; }
  button.is-grey:hover { background-color: #d9d9d9; }
  .stripe-card { margin: 1rem 0; }
  .card-number { margin-top: 2rem; }
  .card-element { width: 55%; margin: 0 auto;
    border: 1px solid grey; border-radius: 3px; padding: 10px;
  }
  .saved-card-btn { margin-top: 1.5rem; }
  .pmt-method { margin-bottom: 1.5rem;
    div { padding: 0.2rem; }
  }
}

@media(min-width: 1024px) {
  .checkout {
    width: 50%; //margin: 0 auto;
  }
}
