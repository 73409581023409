.admin-page {
  .menu { border: 1px solid #000; display: flex; padding: 0.7rem 0;
    ul { list-style-type: none; flex: 11.5; margin: 0; padding: 0;
      li { display: inline-block; cursor: pointer; margin: 0 1rem; }
    }
    ul.add-order { flex: 1.5; padding: 0; text-align: center; }
    div { margin: 1rem; flex: 1; }
  }
  .delv-schd {
    .total { width: 25vw; }
    .total-div { width: 62vw; margin-right: 6vw; }
  }
}

@media(min-width: 1024px) {
  .admin-page {
    .menu { padding: 1rem; //border: 1px solid #000; display: flex;
      ul { margin: 1em; padding-left: 3vw; //list-style-type: none; flex: 11.5;
      }
      ul.add-order { flex: 1.5; padding: 0; text-align: center; }
      //div { margin: 1rem; flex: 1; }
    }
    .delv-schd {
      .total, .total-div { width: auto; margin: 0; }
    }
  }
}
