.hero-image {
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
  url('../../assets/AustralianCows.jpeg') center/cover no-repeat; /* ben-white-unsplash.jpg */
} // agro_1.jpg  // RedCows.jpeg
.hero-title { text-align: center; font-size: 2rem; }

.hero-description { display: block; margin: 0 30px;
  p { font-size: 1.1rem; }
}

@media(min-width: 1024px) {
  .hero-title { font-size: 2.5rem; }
  //.hero-description { display: none; }
}

.shop-now-btn { text-align: center; margin-top: 5vh; }
